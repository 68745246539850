var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chatroom"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"box-left"},[(_vm.userinfo)?_c('div',{staticClass:"box-left-top"},[_c('el-avatar',{attrs:{"shape":"square","size":50,"src":_vm.userinfo.avatar}},[_vm._v("用户")]),_c('span',[_vm._v(_vm._s(_vm.userinfo.nickName))])],1):_vm._e(),(_vm.talkList.length)?_c('div',{staticClass:"box-left-bottom"},_vm._l((_vm.talkList),function(v,k){return _c('div',{key:k,staticClass:"box-left-bottom-item",class:0 == k ? ' active' : '',on:{"click":function($event){return _vm.switchActive(k)}}},[_c('el-avatar',{attrs:{"shape":"square","size":35,"src":v.userShowVo.avatar}},[_vm._v("用户")]),_c('span',{staticClass:"nickName",domProps:{"textContent":_vm._s(
              v.userShowVo.nickName ? v.userShowVo.nickName : '未填写昵称'
            )}}),_c('el-badge',{staticClass:"roleId",attrs:{"is-dot":v.state > 0 ? true : false}},[_c('span',{staticClass:"roleId",style:({ background: _vm.roleIdColor(v.userShowVo.roleId) })},[_vm._v(_vm._s(_vm._f("roleId")(v.userShowVo.roleId)))])])],1)}),0):_vm._e()]),_c('div',{staticClass:"box-right"},[(_vm.talkList[0])?_c('div',{staticClass:"box-right-top"},[_c('el-avatar',{attrs:{"shape":"square","size":50,"src":_vm.talkList[0].userShowVo.avatar}},[_vm._v("用户")]),_c('span',{staticClass:"nickName",domProps:{"textContent":_vm._s(
            _vm.talkList[0].userShowVo.nickName
              ? _vm.talkList[0].userShowVo.nickName
              : '未填写昵称'
          )}})],1):_vm._e(),_c('div',{staticClass:"box-right-bottom"},[_c('div',{staticClass:"box-right-bottom-message"},[_c('div',{staticClass:"box-right-bottom-message-top",on:{"&scroll":function($event){return _vm.getScroll($event)}}},[_c('div',{staticClass:"toload",on:{"click":_vm.topages}},[_vm._v(_vm._s(_vm.toload))]),_vm._l((_vm.talkMessagList),function(v,k){return _c('div',{key:k},[_c('p',[_vm._v(_vm._s(v.sendDate))]),(v.fromUserId == _vm.userinfo.id)?_c('p',{style:({
                  textAlign: v.fromUserId == _vm.userinfo.id ? 'right' : 'left',
                })},[_vm._v(" "+_vm._s(_vm.userinfo.nickName)+" ")]):_c('p',{style:({
                  textAlign: v.fromUserId == _vm.userinfo.id ? 'right' : 'left',
                }),domProps:{"textContent":_vm._s(
                  _vm.talkList[0].userShowVo.nickName
                    ? _vm.talkList[0].userShowVo.nickName
                    : '未填写昵称'
                )}}),_c('div',{class:v.fromUserId == _vm.userinfo.id ? '' : 'fromUser'},[_c('span',{staticClass:"message",domProps:{"innerHTML":_vm._s(v.message)},on:{"click":_vm.lookDom}}),(v.fromUserId == _vm.userinfo.id)?_c('el-avatar',{attrs:{"shape":"square","size":35,"src":_vm.userinfo.avatar}},[_vm._v("用户")]):_c('el-avatar',{attrs:{"shape":"square","size":35,"src":_vm.talkList[0].userShowVo.avatar}},[_vm._v("用户")])],1)])})],2),_c('div',{staticClass:"box-right-bottom-message-bottom"},[_c('upload-preview-img',{on:{"previewImg":_vm.previewImg,"uploadImg":_vm.uploadImg}}),_c('el-input',{attrs:{"id":"input","autofocus":"","type":"textarea","rows":4,"resize":"none","placeholder":"请输入内容"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('span',{on:{"click":_vm.sendMessage}},[_vm._v("发送")])],1)])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.clickImgshow),expression:"clickImgshow"}],staticClass:"demo-image__preview",on:{"click":_vm.hideImg}},[_c('img',{attrs:{"src":_vm.clickImg}})])])}
var staticRenderFns = []

export { render, staticRenderFns }