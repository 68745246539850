<template>
  <div class="chatroom">
    <div class="box">
      <div class="box-left">
        <div class="box-left-top" v-if="userinfo">
          <el-avatar shape="square" :size="50" :src="userinfo.avatar"
            >用户</el-avatar
          >
          <span>{{ userinfo.nickName }}</span>
        </div>
        <div class="box-left-bottom" v-if="talkList.length">
          <div
            @click="switchActive(k)"
            class="box-left-bottom-item"
            :class="0 == k ? ' active' : ''"
            v-for="(v, k) of talkList"
            :key="k"
          >
            <el-avatar shape="square" :size="35" :src="v.userShowVo.avatar"
              >用户</el-avatar
            >
            <span
              class="nickName"
              v-text="
                v.userShowVo.nickName ? v.userShowVo.nickName : '未填写昵称'
              "
            ></span>
            <el-badge :is-dot="v.state > 0 ? true : false" class="roleId">
              <span
                class="roleId"
                :style="{ background: roleIdColor(v.userShowVo.roleId) }"
                >{{ v.userShowVo.roleId | roleId }}</span
              >
            </el-badge>
          </div>
        </div>
      </div>
      <div class="box-right">
        <div class="box-right-top" v-if="talkList[0]">
          <el-avatar
            shape="square"
            :size="50"
            :src="talkList[0].userShowVo.avatar"
            >用户</el-avatar
          >
          <span
            class="nickName"
            v-text="
              talkList[0].userShowVo.nickName
                ? talkList[0].userShowVo.nickName
                : '未填写昵称'
            "
          ></span>
        </div>
        <div class="box-right-bottom">
          <div class="box-right-bottom-message">
            <div
              class="box-right-bottom-message-top"
              @scroll.passive="getScroll($event)"
            >
              <div @click="topages" class="toload">{{ toload }}</div>
              <div v-for="(v, k) of talkMessagList" :key="k">
                <!-- 如果当前发送者id等于当前登录id则显示当前用户昵称，否则显示对方昵称 -->

                <p>{{ v.sendDate }}</p>
                <p
                  v-if="v.fromUserId == userinfo.id"
                  :style="{
                    textAlign: v.fromUserId == userinfo.id ? 'right' : 'left',
                  }"
                >
                  {{ userinfo.nickName }}
                </p>
                <p
                  v-else
                  :style="{
                    textAlign: v.fromUserId == userinfo.id ? 'right' : 'left',
                  }"
                  v-text="
                    talkList[0].userShowVo.nickName
                      ? talkList[0].userShowVo.nickName
                      : '未填写昵称'
                  "
                ></p>
                <div :class="v.fromUserId == userinfo.id ? '' : 'fromUser'">
                  <span
                    class="message"
                    @click="lookDom"
                    v-html="v.message"
                  ></span>
                  <el-avatar
                    v-if="v.fromUserId == userinfo.id"
                    shape="square"
                    :size="35"
                    :src="userinfo.avatar"
                    >用户</el-avatar
                  >
                  <el-avatar
                    v-else
                    shape="square"
                    :size="35"
                    :src="talkList[0].userShowVo.avatar"
                    >用户</el-avatar
                  >
                </div>
              </div>
            </div>
            <div class="box-right-bottom-message-bottom">
              <upload-preview-img
                @previewImg="previewImg"
                @uploadImg="uploadImg"
              ></upload-preview-img>
              <el-input
                id="input"
                autofocus
                type="textarea"
                :rows="4"
                resize="none"
                placeholder="请输入内容"
                v-model="message"
              >
              </el-input>
              <span @click="sendMessage">发送</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="clickImgshow" @click="hideImg" class="demo-image__preview">
      <img :src="clickImg" />
    </div>
  </div>
</template>

<script>
import UploadPreviewImg from "../components/upload/uploadPreviewImg.vue";
export default {
  components: { UploadPreviewImg },
  data() {
    return {
      // 当前点击图片
      clickImg: "",
      clickImgshow: false,
      // url传递id
      queryId: "",
      // 输入的内容
      message: "",
      // 当前点击聊天下标
      active: 0,
      // 聊天列表
      talkList: [],
      //   聊天内容列表
      talkMessagList: [],
      // 聊天记录的总页数
      talkMessagepageNumtotal: "",
      // 聊天内容pages信息
      talkMessagepageNum: 1,
      talkMessagepageSize: 10,
      // 聊天列表pages信息
      talkListpageNum: 1,
      talkListpageSize: 10,
      userinfo: JSON.parse(sessionStorage.getItem("userinfo")),
      // 聊天内容滚动条距离底部的距离
      scrollBottom: "",
      // 加载聊天记录状态
      toload: "点击加载更多",
      // 监听是否为查询聊天记录状态
      loadrecord: false,
    };
  },
  watch: {
    talkMessagList: function () {
      // nextTick：等待DOM更新完成后再执行回调函数
      if (!this.loadrecord) {
        this.$nextTick(() => {
          let container = this.$el.querySelector(
            ".box-right-bottom-message-top"
          );
          container.scrollTop = container.scrollHeight; // 将页面卷到底部
        });
      }
    },
    "$store.state.message"(val) {
      console.log(val);
      //   如果当前接收到发送者id和当前正在聊天者id一样就push进当前聊天内容列表
      if (val.fromUserId == this.talkList[0].userShowVo.id) {
        console.log(val);
        this.talkMessagList.push(val);
        console.log(this.talkMessagList);
      } else {
        this.talkList.map((v, k) => {
          // 如果聊天列表里有与当前接收消息发送者id一样的列表则给发送消息这添加未读图标
          if (v.userShowVo.id == val.fromUserId) {
            // 如果当前数据有未读属性则加1
            if (v.state) {
              this.$set(this.talkList[k], "state", v.state + 1);
              //   v.state + 1;
            } else {
              this.$set(this.talkList[k], "state", 1);

              //   v.state = 1;
            }
            console.log(this.talkList);
          }
        });
      }
    },
  },
  methods: {
    getrouteQuery() {
      this.queryId = this.$route.query.id;
    },
    // 距离底部高度
    getScroll(event) {
      // 滚动条距离底部的距离scrollBottom
      let scrollBottom =
        event.target.scrollHeight -
        event.target.scrollTop -
        event.target.clientHeight;
      this.scrollBottom = scrollBottom;
    },
    // 关闭图片放大
    hideImg(e) {
      // 如果点击的不是图片
        this.clickImgshow = false;
      console.log(this.clickImgshow)
    },
    // 获取当前点击元素
    lookDom(e) {
      // 如果点击的是图片
      if (e.target.src) {
        this.clickImgshow = true;
        this.clickImg = e.target.src;
      }
      console.log(this.clickImgshow)
    },
    // 接收已上传的图片并发送
    uploadImg(img) {
      this.message=`<img style="width: 100%;" src="${img.linkurl}"></img>`
      this.sendMessage()
      // console.log(img.linkurl);
      // if (this.talkList[0].userShowVo) {
      //   this.socket.sendSock({
      //     toId: this.talkList[0].userShowVo.id,
      //     msg: img.linkurl,
      //   });
      // } else {
      //   this.$message({
      //     message: "接收者id不能为空",
      //     type: "error",
      //   });
      // }
    },
    previewImg(img) {
      // for (var i = 0; i < this.talkMessagList.length; i++) {
      //   if (this.talkMessagList[i].fromUserId === this.userinfo.id) {
      //     let item = JSON.parse(JSON.stringify(this.talkMessagList[i]));
      //     item.message = `<img src='${img}'></img>`;
      //     item.sendDate,
      //       item.readDate,
      //       item.updateTime,
      //       (item.createTime = this.$moment().format("YYYY-MM-DD HH:mm:ss"));
      //     this.talkMessagList.push(item);
      //     console.log(this.talkMessagList);
      //     break;
      //   } else if (i == this.talkMessagList.length - 1) {
      //     let item = {
      //       createTime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
      //       fromUserId: this.userinfo.id,
      //       message: `<img src='${img}'></img>`,
      //       readDate: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
      //       sendDate: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
      //       updateTime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
      //     };
      //     this.talkMessagList.push(item);
      //     console.log(this.talkMessagList);
      //     break;
      //   }
      // }
    },
    roleIdColor(roleId) {
      let color = "";
      if (roleId == 0) {
        color = "red";
      } else if (roleId == 1) {
        color = "blue";
      } else if (roleId == 2) {
        color = "green";
      } else if (roleId == 3) {
        color = "violet";
      } else if (roleId == 4) {
        color = "orange";
      } else if (roleId == 5) {
        color = "pink";
      } else if (roleId == 6) {
        color = "purple";
      } else if (roleId == 7) {
        color = "gray";
      } else if (roleId == 8) {
        color = "maroon";
      }
      return color;
    },
    // 切换聊天列表
    async switchActive(k) {
      if (k != 0) {
        this.$set(this.talkList[k], "state", 0);
        this.active = k;

        this.talkList.unshift(this.talkList[k]); // 再添加到第一个位置
        this.talkList.splice(k + 1, 1); // 如果数据组存在该元素，则把该元素删除
        await this.gettalkMessage();
      }
      var pFocus = document.getElementById("input");
      pFocus.focus();
    },
    // 发送消息
    sendMessage() {
      if (this.message.trim() == "") {
        this.$message({
          message: "消息不能为空",
          type: "error",
        });
      } else {
        for (var i = 0; i < this.talkMessagList.length; i++) {
          if (this.talkMessagList[i].fromUserId === this.userinfo.id) {
            let item = JSON.parse(JSON.stringify(this.talkMessagList[i]));
            item.message = this.message;
            item.sendDate,
              item.readDate,
              item.updateTime,
              (item.createTime = this.$moment().format("YYYY-MM-DD HH:mm:ss"));
            this.talkMessagList.push(item);
            console.log(this.talkMessagList);
            break;
          } else if (i == this.talkMessagList.length - 1) {
            let item = {
              createTime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
              fromUserId: this.userinfo.id,
              message: this.message,
              readDate: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
              sendDate: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
              updateTime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
            };
            this.talkMessagList.push(item);
            console.log(this.talkMessagList);
            break;
          }
        }
        if (this.talkList[0].userShowVo) {
          this.socket.sendSock({
            toId: this.talkList[0].userShowVo.id,
            msg: this.message,
          });
          // 发送函数调用完成清空消息
          this.message = "";
        } else {
          this.$message({
            message: "接收者id不能为空",
            type: "error",
          });
        }
      }
    },
    // 获取聊天列表
    async gettalkList() {
      const res = await this.$http({
        method: "get",
        url: "front/talkList/findTalkListByUserId",
        params: {
          pageNum: this.talkListpageNum,
          pageSize: this.talkListpageSize,
          userId: sessionStorage.getItem("userId"),
        },
      });
      if (res.data.status == 200) {
        console.log(res.data.data.list);
        this.talkList = res.data.data.list;
        // 如果url有传递id参数查询id用户信息
        if (this.queryId != "" && this.queryId != undefined) {
          const data = await this.$http({
            method: "get",
            url: "front/user/findById",
            params: {
              id: this.queryId,
            },
          });
          console.log(data.data);
          // 保存状态
          let bool = true;
          // 遍历聊天列表查询有没有跟传递的id用户聊天
          this.talkList.forEach((v, k) => {
            if (
              v.chatId == `${this.queryId}:${this.userinfo.id}` ||
              v.chatId == `${this.userinfo.id}:${this.queryId}`
            ) {
              // 如果有则选中与传递id匹配的聊天室,将状态改为true
              this.switchActive(k);
              bool = false;
            }
          });
          // 如果状态为true说明找到了相同id的聊天室，不用插入新的数据
          if (bool) {
            this.talkList.unshift({
              chatId: `${this.queryId}:${this.userinfo.id}`,
              userShowVo: data.data.data,
            });
          }
        }
      }
    },
    // 聊天记录上一页
    async topages() {
      this.gettalkMessage(this.talkMessagepageNumtotal - 1);
      this.talkMessagepageNumtotal = this.talkMessagepageNumtotal - 1;
    },
    // 获取聊天内容
    // 第一次请求获取总页数，然后请求最后一页
    async gettalkMessage(num) {
      if (num) {
        this.loadrecord = true;
        console.log(this.talkList, this.active);
        const data = await this.$http({
          method: "get",
          url: "front/talkMessage/findTalkMessageList",
          params: {
            pageNum: num,
            pageSize: this.talkMessagepageSize,
            chatId: this.talkList[0].chatId,
          },
        });
        if (data.status == 200) {
          console.log(data.data.data);
          this.talkMessagList = [
            ...data.data.data.list,
            ...this.talkMessagList,
          ];
          console.log(this.talkMessagList);
          this.$nextTick(() => {
            let container = this.$el.querySelector(
              ".box-right-bottom-message-top"
            );
            container.scrollTop =
              container.scrollHeight - this.scrollBottom - 382;
            // 页面渲染完成把查询聊天记录关闭
            this.loadrecord = false;
          });
        }
      } else {
        const res = await this.$http({
          method: "get",
          url: "front/talkMessage/findTalkMessageList",
          params: {
            pageNum: 1,
            pageSize: this.talkMessagepageSize,
            chatId: this.talkList[0].chatId,
          },
        });
        if (res.data.status == 200) {
          this.talkMessagepageNumtotal = res.data.data.pages;
          console.log(this.talkList, this.active);
          const data = await this.$http({
            method: "get",
            url: "front/talkMessage/findTalkMessageList",
            params: {
              pageNum: res.data.data.pages,
              pageSize: this.talkMessagepageSize,
              chatId: this.talkList[0].chatId,
            },
          });
          if (res.status == 200) {
            console.log(data.data.data);
            this.talkMessagList = data.data.data.list;
          }
        }
      }
    },
  },
  mounted() {
    document.onkeyup = (e) => {
      if (e && e.keyCode == 13) {
        this.sendMessage();
      }
    };
    this.getrouteQuery();
  },
  filters: {
    roleId(roleId) {
      if (roleId == 0) {
        return "超管";
      } else if (roleId == 1) {
        return "普通用户";
      } else if (roleId == 2) {
        return "厂家";
      } else if (roleId == 3) {
        return "业务员";
      } else if (roleId == 4) {
        return "设计师";
      } else if (roleId == 5) {
        return "经销商";
      } else if (roleId == 6) {
        return "维修/安装";
      } else if (roleId == 7) {
        return "项目合伙人";
      } else if (roleId == 8) {
        return "城市合伙人";
      }
    },
  },
  async created() {
    await this.gettalkList();
    await this.gettalkMessage();
  },
};
</script>

<style lang="scss" scoped>
.demo-image__preview {
  z-index: 1000;
  width: 100vw;
  height: 80vh;
  position: absolute;
  top: 10vh;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-height: 80vh;
    border: 1px solid #ddd;
  }
}
.fromUser {
  justify-content: flex-start;
  flex-direction: row-reverse;
  & > span:nth-child(1) {
    background: #f3f3f3 !important;
  }
}
.message {
  & ::v-deep img {
    max-height: 100px;
  }
}
.active {
  font-size: 14px;
  height: 50px;
  background: #d2e6f9;
}
.chatroom {
  position: relative;
  height: 100%;
  min-height: 700px;
  background-color: #2e2f3d;
  .box {
    position: relative;
    top: 7%;
    background: #fff;
    height: 85%;
    min-height: 500px;
    width: 70%;
    margin: 0 auto;
    display: flex;
    .box-left {
      min-width: 200px;
      border-right: 1px solid #ddd;
      flex: 2.5;
      .box-left-top {
        padding: 10px;
        background-color: #ff534c;
        span {
          vertical-align: middle;
          color: #333;
          font-size: 14px;
          margin: 5px;
        }
      }
      .box-left-bottom {
        .box-left-bottom-item {
          cursor: pointer;
          padding: 8px;
          span {
            vertical-align: middle;
          }
          .nickName {
            font-size: 14px;
            margin-left: 8px;
            vertical-align: 4px;
          }
          .roleId {
            font-size: 12px;
            border-radius: 2px;
            margin-top: 5px;
            line-height: 16px;
            color: #fff;
            padding: 0 4px;
            float: right;
            margin-right: 5px;
          }
        }
      }
    }
    .box-right {
      flex: 8;
      min-width: 400px;
      background: #fff;
      .box-right-top {
        height: 8%;
        border-bottom: 1px solid #ddd;
        padding: 8px;
        span {
          vertical-align: middle;
        }
        .nickName {
          font-size: 14px;
          margin-left: 8px;
          vertical-align: 4px;
        }
      }
      .box-right-bottom {
        height: 92%;
        .box-right-bottom-message {
          height: 100%;
          .box-right-bottom-message-top {
            .toload {
              font-size: 12px;
              color: #5a606b;
              text-align: center;
              margin-bottom: 12px;
              cursor: pointer;
            }
            height: 70%;
            border-bottom: 1px solid #ddd;
            overflow-y: auto;
            & > div {
              margin: 20px;
              & > p:nth-child(1) {
                font-size: 13px;
                color: #a1a6af;
                text-align: center;
                margin-bottom: 13px;
              }
              & > p:nth-child(2) {
                text-align: right;
                box-sizing: border-box;
                font-size: 12px;
                color: #a1a6af;
                padding: 0 48px;
                margin-bottom: 3px;
              }
              & > div {
                display: flex;
                justify-content: flex-end;
                span:nth-child(1) {
                  max-width: 80%;
                  vertical-align: 9px;
                  display: inline-block;
                  border-radius: 4px;
                  padding: 12px 9px;
                  word-break: break-all;
                  background-color: #d0e9ff;
                  margin: 0px 10px;
                  font-size: 13px;
                }
              }
            }
          }
          .box-right-bottom-message-bottom {
            height: 25%;
            & > div:nth-child(1) {
              padding-top: 5px;
              padding-left: 20px;
            }
            &::v-deep .el-textarea__inner {
              border: 0;
            }
            span {
              float: right;
              font-size: 14px;
              border: 1px solid #ddd;
              z-index: 100;
              border-radius: 4px;
              padding: 5px 20px;
              margin-right: 80px;
              position: relative;
              bottom: 15px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>